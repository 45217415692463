<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        a) From the set of Lewis structures shown below, determine the
        <b>best Lewis structure</b> for
        <chemical-latex :content="molecule" />
        . Please note that formal charges are intentionally left out of the structures.
      </p>

      <p class="pl-6 mb-3">
        <v-img style="width: 550px" :src="imageName" />
      </p>

      <v-radio-group v-model="inputs.bestStructure" row :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block mr-7 mb-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        b) What is the <b>molecular geometry</b> around the
        <stemble-latex :content="centralAtom" />
        for the structure selected in part a)?
      </p>

      <v-radio-group v-model="inputs.shape" dense :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="option.value"
          class="d-inline-block pl-7 mb-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        c) What is the <b>hybridization</b> of the
        <stemble-latex :content="centralAtom" />
        for the structure chosen in part a)?
      </p>

      <v-radio-group v-model="inputs.hybridization" dense :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="option.value"
          class="d-inline-block pl-7"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        d) Do any of the atoms in the structure chosen in part a)
        <b>violate</b> the octet rule?
      </p>

      <v-radio-group v-model="inputs.octet" dense :disabled="!allowEditing">
        <v-radio
          v-for="option in options4"
          :key="option.value"
          class="d-inline-block pl-7"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question268',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        bestStructure: null,
        shape: null,
        hybridization: null,
        octet: null,
      },
      options1: [
        {expression: '$\\text{Structure A}$', value: 'a'},
        {expression: '$\\text{Structure B}$', value: 'b'},
        {expression: '$\\text{Structure C}$', value: 'c'},
        {expression: '$\\text{Structure D}$', value: 'd'},
      ],
      options2: [
        {expression: '$\\text{Linear}$', value: 'linear'},
        {expression: '$\\text{Bent}$', value: 'bent'},
        {expression: '$\\text{Tetrahedral}$', value: 'tetrahedral'},
        {expression: '$\\text{Trigonal Planar}$', value: 'trigonalPlanar'},
        {
          expression: '$\\text{Trigonal Pyramidal}$',
          value: 'trigonalPyramidal',
        },
        {expression: '$\\text{Square Planar}$', value: 'squarePlanar'},
        {expression: '$\\text{Seesaw}$', value: 'seesaw'},
        {expression: '$\\text{Square Pyramidal}$', value: 'squarePyramidal'},
        {expression: '$\\text{T-shape}$', value: 'tShape'},
        {
          expression: '$\\text{Trigonal Bipyramidal}$',
          value: 'trigonalBipyramidal',
        },
        {expression: '$\\text{Octahedral}$', value: 'octahedral'},
      ],
      options3: [
        {expression: '$\\text{sp}$', value: 'sp'},
        {expression: '$\\text{sp}^2$', value: 'sp2'},
        {expression: '$\\text{sp}^3$', value: 'sp3'},
        {expression: '$\\text{sp}^4$', value: 'sp4'},
        {expression: '$\\text{sp}^2\\text{d}^2$', value: 'sp2d2'},
        {expression: '$\\text{sp}^3\\text{d}$', value: 'sp3d'},
        {expression: '$\\text{sp}^3\\text{d}^2$', value: 'sp3d2'},
        {expression: '$\\text{spd}^3$', value: 'spd3'},
        {expression: '$\\text{sp}^3\\text{d}^3$', value: 'sp3d3'},
      ],
      options4: [
        {expression: '$\\text{Yes}$', value: 'yes'},
        {expression: '$\\text{No}$', value: 'no'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    imageName() {
      if (this.versionNumber.value === 1) {
        return '/img/assignments/I3_Lewis.png';
      } else if (this.versionNumber.value === 2) {
        return '/img/assignments/N3_Lewis.png';
      } else {
        return '';
      }
    },
    molecule() {
      if (this.versionNumber.value === 1) {
        return 'I3^-';
      } else if (this.versionNumber.value === 2) {
        return 'N3^-';
      } else {
        return '';
      }
    },
    centralAtom() {
      if (this.versionNumber.value === 1) {
        return 'central iodine atom';
      } else if (this.versionNumber.value === 2) {
        return 'central nitrogen atom';
      } else {
        return '';
      }
    },
  },
};
</script>
